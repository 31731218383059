body {
  font-size: 14px;
}

.question-div .btn-submit-review,
.question-div .btn-submit-review:hover {
  border: unset;
  border-radius: 40px;
  width: 100%;
  height: 50px
}

.question-box h6 {
  font-weight: 600;
}

.btn,
.question-div .btn-submit-review,
.question-div .btn-submit-review:hover,
.navbar .btn-signin,
.navbar .btn-signin.active,
.active {
  color: white;
  background-color: #ffa500;
}

.cat.active {
  border-color: #ffa500;
  box-shadow: 0 2px 4px 0 rgba(12, 2, 2, .2);
}

.navbar .btn-signin {
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.card {
  box-shadow: 0 2px 4px 0 rgba(12, 2, 2, .2);
}

.card:hover {
  box-shadow: 0 16px 16px 0px rgba(2, 0, 0, 0.2);
}

label:hover {
  cursor:pointer;
}

.cat {
  display: inline-block;
  margin: 0 5px 5px 0;
  border-radius: 40px;
  border: 1px solid #000;
}

.cat label {
  padding: 5px 10px;
}

.cat label input {
  display: none;
}

.hotel-div {
  position: sticky;
  width: 100%;
  top: 20px;
}

@media (max-width: 992px) {
  .question-div {
    padding-left: 0px !important;
    padding-top: 60px;
  }
  .hotel-div {
    padding-bottom: 60px !important;
    border-right: 0px solid #C0C0C0 !important;
    border-bottom: 1px solid #C0C0C0;
  }
}

.error-page-div {
  position: relative;
  box-shadow: 400px 0px 250px 0px #000000 inset;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow: hidden
}

.errorpage-header {
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.errorpage-header p {
  width: 170px;
  color: #ffffff;
  font-size: 55px;
  font-weight: 250;
  border-bottom: 3px Solid #FF0000;
}

.errorpage-notfound {
  height: 140px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.errorpage-notfound p {
  width: 600px;
  color: #ffffff;
  font-size: 85px;
  line-height: 1;
  font-weight: 400;
}

.errorpage-para {
  height: 150px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.errorpage-para p {
  width: 380px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 120;
}

.errorpage-contact-div {
  width: 100%;
}

.errorpage-contact {
  height: 100%;
  display: flex;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
}

.errorpage-link {
  width: 100%;
  color: #16a278;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 5px 0px 5px 0px;
}

.errorpage-icon-div {
  position: absolute;
  width: 180px;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
}

.errorpage-icon {
  width: 50px;
  color: #fff;
}

@media (max-width: 526px) {
  .errorpage-notfound{
    height: 200px;
  }
  .errorpage-para {
    height: 200px;
  }
}

@media (max-width: 350px) {
  .errorpage-notfound{
    height: 150px;
  }
  .errorpage-notfound p {
    font-size: 65px;
  }
}

.reservation-details {
  margin-top: 10px;
}

.reservation {
  margin-top: 15px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .reservation {
    display: flex;
    justify-content: space-between;
  }
}

.toast-div{
  position: fixed;
  right: 2rem;
  top: 5%;
}

.validation-msg{
  color: #FF0000;
  padding-top: 8px;
}

.submitted-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 20px 3px 20px;
}

.check-icon {
  color: #228B22;
  margin-bottom: 120px;
}

.attachment-div {
  position: relative;
  width: 200px;
  height: 150px;
  margin-bottom: 10px;
}

.remove-attachment {
  position: absolute;
  right: -10px;
  top: -10px;
  color: #98989894;
  cursor:pointer;
}

.file-input {
  display: none;
}

.input-file-div {
  display: flex;
}

.file-count {
  margin-left: 20px;
  margin-top: 7px;
  font-size: 16px;
}

.textarea-count {
  float: right;
  padding: 0.1rem 0 0 0;
  font-size: 0.875rem;
}

.footer-container {
  width: 100%;
}

.footer-col-left {
  display: flex;
  align-items: center;
}

.footer-col-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.footer-para {
  padding-top: 18px;
  margin-right: 7px;
  font-size: 13px;
}

.footer-icon-div {
  border-radius: 50px;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-left: 5px;
  padding-left: 10px;
  padding-top: 1px;
}

.footer-icon {
  color: #fff;
}

.footer-icon-div:hover {
  background-color: #23527c !important;
}
